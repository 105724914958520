<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="about-page" class="content-height">
      <PageLoader />
      <!--<InternalNavbar />-->
      <div id="about-us-internal">
        <div class="hr"></div>
        <div class="container">
          <div class="content">
          <div class="titleS">
            <span>{{ row[$i18n.locale+'_title'] }}</span>
            <!-- {{this.$route.query.page}} -->
          </div>
            <div class="pragraph">
              <p v-html="row[$i18n.locale+'_desc']"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data() {
    return {
      
      row: []
    };
  },
  methods: {},
  mounted() {
    // this.loading(7000);
    this.$http
      .get("pagesBy/4")
      .then(response => {
        this.row = response.data.data;
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  }
};
</script>
